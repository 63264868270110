// import Card from './components/Card/Card'
// import Component from './views/Components/Components'
import axios from "axios";
import Cookies from "js-cookie";
import { lazy, Suspense, useContext, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import "./components/Loader/Loader.scss";
import apiConstants from "./constants/apiConstants";
import { SUCCESS_STATUS_CODE } from "./constants/constant";
import MainContext from "./context/main-context";
// Code-Splitting - Lazy Loading
const Header = lazy(() => import("./components/Header/Header"));
const Loader = lazy(() => import("./components/Loader/Loader"));
const Ad = lazy(() => import("./components/Ad/Ad"));
const Error = lazy(() => import("./components/Error/Error"));
const Footer = lazy(() => import("./components/Footer/Footer"));
const Dashboard = lazy(() => import("./views/Dashboard/Dashboard"));
const PowerList = lazy(() => import("./views/Powerlist/Powerlist"));
const Leaders = lazy(() => import("./views/Leaders/Leaders"));
const Bookmark = lazy(() => import("./views/Bookmark/Bookmark"));
const NominateInnovators = lazy(() =>
  import("./views/NominateInnovators/NominateInnovators")
);
const ContentList = lazy(() => import("./views/ContentList/ContentList"));
const SingleTopic = lazy(() => import("./views/SingleTopic/SingleTopic"));
const ExpertiseLeaders = lazy(() =>
  import("./views/ExpertiseLeaders/ExpertiseLeaders")
);

const LeadersProfile = lazy(() =>
  import("./views/LeadersProfile/LeadersProfile")
);
const ContentCarousal = lazy(() =>
  import("./components/ContentCarousal/ContentCarousal")
);
const LeadersContent = lazy(() =>
  import("./views/LeadersContent/LeadersContent")
);
const ContentDetail = lazy(() => import("./views/ContentDetail/ContentDetail"));
const ExternalPage = lazy(() => import("./views/ExternalPage/ExternalPage"));
const Rankings = lazy(() => import("./views/Ranking/Ranking"));
const Privacy = lazy(() => import("./views/Privacy/Privacy"));
const AllTopics = lazy(() => import("./views/AllTopics/AllTopics"));
const TopicContentList = lazy(() =>
  import("./views/TopicContentList/TopicContentList")
);
// const Components = lazy(() => import("./views/Components/Components"));
const Modal = lazy(() => import("./components/Modal/Modal"));
const UserProfile = lazy(() => import("./views/UserProfile/UserProfile"));
const RegisterAsLeader = lazy(() =>
  import("./views/RegisterAsLeader/RegisterAsLeader")
);
const SearchContent = lazy(() => import("./views/SearchContent/SearchContent"));

function App() {
  let context = useContext(MainContext);
  const location = useLocation();
  useEffect(() => {
    if (
      Cookies.get("refresh_token") &&
      Cookies.get("refresh_token") !== "" &&
      (!Cookies.get("access_token") || Cookies.get("access_token") === "")
    ) {
      const refreshToken = Cookies.get("refresh_token");
      // refresh token
      axios
        .post(apiConstants.auth.refreshToken + "?refreshToken=" + refreshToken)
        .then((response) => {
          if (response.data.apiResponseStatus.code === SUCCESS_STATUS_CODE) {
            document.cookie =
              "access_token=" +
              response.data.responseObject.oauth2AccessToken.access_token +
              "; path=/; max-age=3600";
            document.cookie =
              "refresh_token=" +
              response.data.responseObject.oauth2AccessToken.refresh_token +
              "; path=/; max-age=2629800";
            window.localStorage.setItem(
              "access_token",
              response.data.responseObject.oauth2AccessToken.access_token
            );
            /* window.localStorage.setItem(
              "refresh_token",
              response.data.responseObject.oauth2AccessToken.refresh_token
            );*/
          }
        });
    }
  });
  return (
    <div>
      <Suspense
        fallback={
          <div className='loader-block blank-page-loader'>
            <div
              className='loader'
              style={{ marginTop: window.innerHeight / 2 }}>
              <img
                className='logo-img'
                src='https://engatica-pub.s3.amazonaws.com/Documents/Engatica+logo+icon.svg'
                alt='engatica'
              />
            </div>
          </div>
        }>
        {!location.pathname.includes("/privacy") ? <Header /> : ""}
        {context.isModal && (
          <Modal
            type='signup'
            //{context.modalType}
            modalType='login'
            onConfirm={context.setModal}
          />
        )}
        {context.loader > 0 && <Loader />}
        <Switch>
          <Route path='/' exact component={Dashboard}></Route>
          {/* <Route path='/dashboard' component={Dashboard} /> */}
          <Route path='/ranking/:category' component={PowerList} />
          {(context.isLoggedIn || Cookies.get("access_token")) && (
            <Route path='/user-profile' component={UserProfile} />
          )}
          <Route path='/register/innovator' component={RegisterAsLeader} />
          <Route path='/privacy' component={Privacy} />
          <Route path='/ranking' component={Rankings} />
          {/* <Route path='/nominate/innovators' component={NominateInnovators} /> */}
          <Route path='/topics' component={AllTopics} />
          <Route
            path='/topic/:category/:content'
            component={TopicContentList}
          />
          {(context.isLoggedIn || Cookies.get("access_token")) && (
            <Route
              key={context.isAction}
              path='/bookmark'
              component={Bookmark}
            />
          )}
          <Route
            path='/innovators/:leadersName/:id/profile'
            component={LeadersProfile}
            exact
          />
          <Route
            path='/expertise-innovators/:id'
            component={ExpertiseLeaders}
          />

          <Route
            path='/innovators/:leaderId/content'
            component={LeadersContent}
            exact
          />
          {/* contentType can be blog, video, ebook */}
          <Route path='/innovators' component={Leaders} />
          <Route path='/content/topic/:topicId' component={SingleTopic} exact />
          <Route path='/external' component={() => <ExternalPage />} />
          <Route path='/search/:result' component={SearchContent} />
          <Route path='/:contentType' exact component={ContentList} />
          <Route path='/:type/:title' component={ContentDetail} />
          <Route path='/error' component={Error} />
          {/* Components examples */}
          {/* <Route path='/component' component={Components} /> */}
          {/* external view starts */}
          {/* external view ends */}
          <Route component={Dashboard} />
        </Switch>
        {!location.pathname.includes("external") &&
        !location.pathname.includes("/user-profile") &&
        !location.pathname.includes("/error") &&
        !location.pathname.includes("/nominate") &&
        !location.pathname.includes("/privacy") ? (
          <div>
            <Ad />
            <div className='space-small'></div>
            <ContentCarousal type='TOP_CONTENT' />
            <div className='space-small'></div>
          </div>
        ) : (
          ""
        )}
        {!location.pathname.includes("/privacy") ? <Footer /> : ""}
      </Suspense>
    </div>
  );
}

export default App;
